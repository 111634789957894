<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1> {{ $t("vod-detail-title-contents")}}</h1>
    </div>
    <div class="contents vodDetail">
      <VodContent v-if="visible == 1" :contentsId="contentsId" />
    </div>
  </div>
</template>

<script>
import VodContent from "@/components/vod/VodContent";

export default {
  components: { VodContent, /*SdUpload*/ },
  name: "ContentsDetail",
  data() {
    return {
      visible: 1,
      contentsId: "",
    };
  },
  created() {
    this.contentsId = this.$route.query.contentsId;
  },
  mounted() {
    // this.getVodDetail();
  },
  methods: {
    handleTab(id) {
      this.visible = id;
    },
    handleRefresh() {
      this.$router.go();
    },
  },
};
</script>
